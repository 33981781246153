import logo from './logo.svg';
import './App.css';
function Project(props) {
    return (
        <p>
            <a href={props.link} target="_blank" rel="noopener noreferrer">
                {props.project}
            </a>
        </p>
    );
}
function Projects() {
    const projects = ['Owner @ Soundboard Hub (19.5k)', 'Mod @ r/drawing (9.4k)', 'Dev @ EasySystems', 'Admin @ MrSpherical (5.3k)', 'Mod @ Viggle (4.35m)'];
    const links = ['https://discord.gg/sfx', 'https://discord.gg/drawing', 'https://easysystems.live', 'https://discord.gg/mrspherical', 'https://discord.gg/viggle'];

    return (
        <div className="App">
            <header className="App-header">
             <h1>What are my projects?</h1>
             <ul>
                 {projects.map((project, index) => <Project project={project} link={links[index]}/>)}
             </ul>
            </header>
        </div>
    );
}

export default Projects;
