import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Buttons from './Buttons';
import Button from '@mui/material/Button';
import TypeIt from "typeit-react";
class App extends Component {



  render() {
    return (
        <div className="App">
          <header className="App-header">
            <img
                src="https://storeuk.taylorswift.com/cdn/shop/files/8wU8sU2k4gvB0i8MJ4wakKkWpJwpOpAO-4_1800x1800_1_ff25ae4f-f5f2-4221-9c95-0803d97e6dfb.webp?v=1717679290&width=1800"
                className="App-logo" alt="logo"/>
            <p>
              <TypeIt
                  getBeforeInit={(instance) => {
                    instance
                      .type("Hi, I'm zee")
                      .pause(1250)
                      .delete(2)
                      .pause(500)
                      .type("ippy")
                      .pause(1250)
                      .type("bonzo")
                      .pause(1250)
                      .delete(10)
                      .pause(500)
                      .type("sometimes smart")
                      .pause(1250)
                      .delete(5)
                      .type("funny")
                      .pause(1250)
                      .delete(19)
                      .type("Read on below!");
                      return instance;
                    }}
              />;
            </p>
            <p>
              I do creative things sometimes :)
            </p>
          </header>
          <Buttons/>
        </div>
    );
  }
};
export default App;
